//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'
import Header from '~/components/Header.vue'
import Footer from '~/components/Footer.vue'
import CookieAlert from '~/components/UI/Cards/CookieAlert'
import LinkToAlert from '~/components/UI/Cards/LinkToAlert'
import utmMixin from '~/common/mixins/UtmMixin'
import { getCookie, setCookie } from '~/common/helpers/cookie'
import { MONTH } from '~/common/constants/time'
import MobileAppPanel from '~/components/PageParts/MobileAppPanel'
import tildaContentMixin from '~/common/mixins/TildaContentMixin'
import {
  YANDEX_VERIFICATION_LIST,
  GOOGLE_VERIFICATION_LIST,
} from '~/common/constants/yandexVerificationList'
import ModalGiveaway from "~/components/ModalGiveaway.vue";

export default {
  name: 'MainLayout',
  components: {
    MobileAppPanel,
    Header,
    Footer,
    CookieAlert,
    LinkToAlert,
    ModalGiveaway,
  },
  mixins: [utmMixin, tildaContentMixin],
  scrollToTop: true,
  transition: {
    name: 'fade',
    mode: 'out-in',
  },
  data() {
    return {
      menuItems: [
        { title: 'Вакансии', link: '/vacancies', target: '_self' },
        { title: 'Обучение', link: '/training', target: '_self' },
        { title: 'Акции', link: '/promo', target: '_self' },
        { title: 'Для бизнеса', link: '/partners', target: '_self' },
      ],
      isMobileAppPanel: true,
      isGiveawayOpen: false,
    }
  },
  head() {
    if (process.client) {
      if (process.env.APP_MODE === 'production') {
        const loadMeta = () => {
          const meta = [
            { name: 'yandex-verification', content: 'ab7c302b4f2284c8' },
            {
              name: 'google-site-verification',
              content: 'CLfDXB7vdDCQCGNiQEyEYYYfhG7aWjEhpIeLnjMAhHY',
            },
          ]
          const domain = this.config?.city?.domain
          const yandexVerificationByDomain = YANDEX_VERIFICATION_LIST[domain]
          const googleVerificationByDomain = GOOGLE_VERIFICATION_LIST[domain]
          if (googleVerificationByDomain) {
            const val = {
              name: 'google-site-verification',
              content: googleVerificationByDomain,
            }
            meta.push(val)
          }
          if (yandexVerificationByDomain) {
            const val = {
              name: 'yandex-verification',
              content: yandexVerificationByDomain,
            }
            meta.push(val)
          }

          return meta
        }
        const meta = loadMeta()
        return {
          meta,
        }
      } else if (process.env.APP_MODE === 'development') {
        return {
          meta: [
            { name: 'robots', content: 'noindex, nofollow' },
            { name: 'yandex-verification', content: '9011c9ad7d7471c0' },
          ],
        }
      }
    }
  },
  computed: {
    ...mapState('meta', {
      pageMeta: 'pageMeta',
    }),
    ...mapState('core', ['subDomain']),
    ...mapState('core', ['config']),
    headerLinks() {
      return this.menuItems.filter((item) => {
        return item
      })
    },
    hasAccessMobileAppConfirm() {
      if (process.client) {
        return getCookie('rabotut:user:confirmMobile')
      }
      return true
    },
    footerLinks() {
      return this.menuItems.concat(
        { title: 'Карта сайта', link: '/sitemap', target: '_self' },
        { title: 'О нас', link: '/company', target: '_self' },
        { title: 'Работа у нас', link: '/for-partners', target: '_self' },
        { title: 'Помощь', link: '/help', target: '_self' },
        { title: 'Контакты', link: '/contacts', target: '_self' },
        {
          title: 'Политика конфиденциальности ',
          link: '/rabotut_policy.pdf',
          target: '_blank',
        }
      )
    },
  },
  mounted() {
    const isDebug = localStorage.getItem('debug') === '1'
    if (isDebug) {
      console.log(
        `%c # loaded config to ${this.subDomain ? `${this.subDomain}.` : ''}${
          this.config.rootDomain
        }`,
        'color: pink'
      )
      console.log('config', this.config)
      console.log('detect and save utm')
      console.log('url_query', JSON.stringify(this.filteredUtm))
      console.log('route', this.$route);
    }
    setCookie('rabotut:utm', JSON.stringify(this.filteredUtm) || '', {
      'max-age': MONTH,
      domain: this.config.cookieDomain,
    })


    setCookie('rabotut:click_id', this.filteredClickId, {
      'max-age': MONTH,
      domain: this.config.cookieDomain,
      path: '/',
    })

    if (isDebug) {
      console.log('cookie', getCookie('rabotut:utm'))
    }
    if (this.subDomain === 'm') {
      this.$router.push('/city/0c5b2444-70a0-4932-980c-b4dc0d3f02b5/vacancies')
    }
  },
  methods: {
    ...mapMutations('user', ['setCityMounted']),
    onCloseMobileAppPanel() {
      setCookie('rabotut:user:confirmMobile', '1', {
        domain: this.config.cookieDomain,
      })
      this.isMobileAppPanel = false
    },
  },
}

import { get, pickBy } from 'lodash'
import { getCookie } from '~/common/helpers/cookie'

export default {
  computed: {
    utm() {
      return {
        utm_campaign: get(this.$route.query, 'utm_campaign', ''),
        utm_content: get(this.$route.query, 'utm_content', ''),
        utm_medium: get(this.$route.query, 'utm_medium', ''),
        utm_source: get(this.$route.query, 'utm_source', ''),
        utm_term: get(this.$route.query, 'utm_term', ''),
      }
    },
    filteredUtm() {
      let res = {}
      if (Object.keys(pickBy(this.utm)).length) {
        Object.entries(this.utm).forEach(([key, value]) => {
          res[key.substr(4)] = value
        })
      } else if (Object.keys(pickBy(getCookie('rabotut:utm'))).length) {
        res = JSON.parse(getCookie('rabotut:utm'))
      } else {
        Object.entries(this.utm).forEach(([key, value]) => {
          res[key.substr(4)] = value
        })
      }
      return res
    },
    filteredClickId() {
      const clickIdFromQuery = get(this.$route.query, 'click_id', '')

      if (clickIdFromQuery) {
        return clickIdFromQuery
      } else {
        const fromCookie = getCookie('rabotut:click_id')
        if (fromCookie) {
          return fromCookie
        }
        return ''
      }
    },
  },
}
